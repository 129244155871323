<script lang="ts" setup>
import { sleep } from '@antfu/utils';

const emits = defineEmits(['focus', 'blur', 'submit', 'select-suggestion']);

type Suggestion = {
  value: string;
  data?: any;
};

const modelValue = defineModel<string>({ default: '' });

const props = defineProps<{
  name: string;
  type?: string;
  isLoading?: boolean;
  error?: string;
  hint?: string;
  maskOptions?: any;
  prependIcon?: string;
  appendButtonIcon?: string;
  placeholder?: string;
  disabled?: boolean;
  autofocus?: boolean;
  autocomplete?: boolean;
  notHideSuggestionsOnClick?: boolean;
  suggestions?: Suggestion[];
  selectedSuggestion?: Suggestion;
}>();

const input = ref();
const isFocused = ref(false);
const isHovered = ref(false);
const isSuggestionsBlockHovered = ref(false);

const computedClasses = computed(() => [
  { 'input-field__focus': isFocused.value },
  { 'input-field__hover': isHovered.value },
  { 'input-field__error': !!props.error },
  { 'input-field__prepend-icon': !!props.prependIcon },
]);
const isShowBottomText = computed(() => !!props.error || !!props.hint);
const isShowSuggestionsContainer = computed(() => props.autocomplete && !!props.suggestions?.length && isFocused.value);

onMounted(() => {
  if (props.autofocus) {
    focus();
  }
});

function submit() {
  if (modelValue.value.length) {
    emits('submit');
    blur();
  }
}

function focus() {
  if (input.value) input.value.focus();
  isFocused.value = true;
  emits('focus');
}

function blur() {
  if (input.value) input.value.blur();
  isFocused.value = false;
  emits('blur');
}

async function handleBlur() {
  await sleep(100);

  if (
    isSuggestionsBlockHovered.value &&
    props.notHideSuggestionsOnClick &&
    props.suggestions &&
    props.suggestions.length > 0
  ) {
    focus();
  } else {
    blur();
  }
}

function handleSuggestionClick(suggestion: Suggestion) {
  emits('select-suggestion', suggestion);
}

function handleKeyboardEvents(e: KeyboardEvent) {
  if (e.key === 'Escape') {
    blur();
  }

  if (props.suggestions && props.suggestions.length > 0) {
    if (e.key === 'ArrowUp') {
      console.log('up');
    }
    if (e.key === 'ArrowDown') {
      console.log('down');
    }
  }
}
</script>

<template>
  <div>
    <div
      :class="computedClasses"
      class="input-field"
      @keydown="handleKeyboardEvents"
    >
      <component
        :is="`Svgo${prependIcon}`"
        v-if="prependIcon"
        :font-controlled="false"
        class="input-field__icon"
        filled
      />

      <input
        ref="input"
        v-model="modelValue"
        v-maska:[maskOptions]
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :type="type ?? 'text'"
        class="input-field__input"
        @blur="handleBlur"
        @focus="focus"
        @mouseout="isHovered = false"
        @mouseover="isHovered = true"
        @keydown.enter="submit"
      />

      <Transition name="loader-scooter">
        <LoaderBlock
          v-if="isLoading"
          class="animate-pulse mx-2"
          size="6"
          title="Идет загрузка..."
        />
      </Transition>

      <button
        v-if="appendButtonIcon"
        :disabled="!modelValue.length"
        class="input-field__button"
        type="button"
        @click="submit"
      >
        <component
          :is="`Svgo${appendButtonIcon}`"
          :font-controlled="false"
          class="w-5 h-5"
          filled
        />
      </button>

      <FoldableBlock v-model="isShowSuggestionsContainer">
        <ul
          class="input-field__autocomplete-container"
          @mouseleave="isSuggestionsBlockHovered = false"
          @mouseover="isSuggestionsBlockHovered = true"
        >
          <li
            v-for="(suggestion, key) in suggestions"
            :key="key"
            :class="{ 'text-hell-green': suggestion.value === selectedSuggestion?.value }"
            class="input-field__autocomplete-item"
            @click="handleSuggestionClick(suggestion)"
          >
            <span v-html="highlightTextOccurrence(suggestion.value, modelValue)" />
          </li>
        </ul>
      </FoldableBlock>
    </div>

    <FoldableBlock v-model="isShowBottomText">
      <div class="text-xs py-1 px-2">
        <span
          v-if="error"
          class="text-hell-red"
          v-html="error"
        />

        <span v-else-if="hint">{{ hint }}</span>
      </div>
    </FoldableBlock>
  </div>
</template>

<style lang="scss" scoped>
.input-field {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: $hell-black-5;
  font-size: 16px;
  transition: all 0.2s;

  &__hover {
    border-color: $hell-black-20;
  }

  &__focus {
    border-color: $hell-black-40;
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: $hell-black-40;
  }

  &__input {
    width: 100%;
    height: 34px;
    padding: 8px 11px;
    border-radius: inherit;
    background-color: $hell-black-5;
    color: $hell-black;

    &::placeholder {
      color: $hell-black-40;
    }

    &:hover,
    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }

    &[type='number'] {
      appearance: textfield;
    }

    &:disabled {
      background-color: $hell-black-5;
    }
  }

  &__error {
    border-color: $hell-red;
  }

  &__prepend-icon {
    padding-left: 8px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 8px;
    border-left: 1px solid $hell-black-20;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: $hell-black-80;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: $hell-black-20;
    }

    &:disabled {
      background-color: inherit;
      color: $hell-black-40;
      cursor: inherit;
    }
  }

  &__autocomplete-container {
    position: absolute;
    top: 105%;
    left: -1px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: calc(100% + 2px);
    height: fit-content;
    max-height: 200px;
    padding: 8px 11px;
    border: 1px solid $hell-black-10;
    border-top: none;
    border-radius: 12px;
    background-color: $white;
  }

  &__autocomplete-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    color: $hell-black-80;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: $label-violet;
    }
  }
}
</style>
